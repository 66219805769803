@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap');

* {
    font-family: 'Open Sans', sans-serif;
}

html {
    /*overscroll-behavior: contain;*/
    /*overflow: hidden;*/

}

body {
    margin: 0;
    padding: 0;
    font-size: 22px;
    /*touch-action: none;*/
    /*overflow: hidden;*/
}

a {
    color: #000;
    text-decoration: none;
}

.text-page {
    font-size: 16px;
    flex-direction: column !important;
    padding: 25px 0;
}

.text-page h3 {
    margin-left: 0 !important;
}

/*---------- Form ----------*/
form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

input, select, textarea {
    padding: 15px 20px;
    border-radius: 30px;
    border: solid 1px #333;
    font-size: 16px;
}

input[type='file'] {
    background-color: #fff;
}


input[type='text']:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

input[type='number']:disabled {
    background-color: #e3e3e3;
    cursor: not-allowed;
}

.input-counter {
    padding: 8px 14px;
    width: 40px;
    text-align: center;
    -moz-appearance: textfield;
}

.input-counter::-webkit-outer-spin-button,
.input-counter::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


input[type="checkbox"] {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 8px;
    margin-top: 6px;
}

.input-error {
    border: 2px solid rgba(238, 8, 27, 0.8);
}

.form-control {
    display: flex;
    flex-direction: column;
    margin: 10px 15px;
    text-align: left;
    padding: 0 20px;
}

.form-control label,
.form-inline-control label {
    display: block;
    margin-bottom: 8px;
    font-size: .8em;
    text-transform: uppercase;
}

.form-inline-control {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: center;
    padding: 5px 35px 5px;

}

.form-inline-control input {
    width: auto;
}

.form-buttons {
    margin-top: 15px;
}

.form-error {
    background-color: rgba(238, 8, 27, 0.44);
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
}

.form-success {
    background-color: rgba(8, 238, 27, 0.44);
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
}

.counter-input {
    margin: 10px 0;
}

/*---------- Button ----------*/
button {
    cursor: pointer;
    margin: 0 5px;
    color: #000;
    font-size: 18px;
}

button:disabled {
    background: none;
    background-color: grey !important;
    box-shadow: none;

}

button:hover:disabled {
    background: none;
    background-color: grey !important;
}

button:active:disabled {
    box-shadow: none;
}


.icon-button {
    border: 0;
    background-color: #fff0;
    border-radius: 50%;
    padding: 10px 12px;
}

.icon-button:hover {
    background-color: #e7e7e7;
}

.icon-button:active {
    background-color: rgba(231, 231, 231, 0.6);
}

.primary-button,
.secondary-button {
    border: solid 1px #ccc;
    border-radius: 30px;
    padding: 10px 20px;
    box-shadow: 0 5px 5px #0007;
}

.small-btn {
    padding: 5px 15px !important;
    border-radius: 20px;
}

.primary-button {
    background: linear-gradient(45deg, #1da649, #79d72d);
    color: #fff;
    padding: 15px 20px;
    border: 0
}

.primary-button:hover {
    background: linear-gradient(45deg, #79d72d, #1da649);
}

.primary-button:active {
    background: linear-gradient(45deg, #69bb27, #18863b);
    box-shadow: 0 3px 5px #0007;

}

.secondary-button {
    border: 2px solid #eee;
    background-color: #f8f8f8;
}

.secondary-button:hover {
    border: 2px solid #1da649;
    color: #1da649
}

.secondary-button:active {
    background: linear-gradient(45deg, #69bb27, #18863b);
    color: #fff;
    border: 2px solid #fff;
}

.square-corners {
    border-radius: 10px;
}

.danger-button {
    background: linear-gradient(45deg, #d72d60, #811734);
    border: none;
    padding: 28px 25px;
    border-radius: 0 10px 10px 0;
    margin: 0;
    height: 100%;
}

.danger-button:hover {
    background: linear-gradient(45deg, rgba(215, 45, 96, 0.81), rgba(129, 23, 52, 0.85));
}

/*---------- Nav ----------*/
.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    box-shadow: 0 1px 15px #0000;
}

.nav-mobile-controls {
    display: flex;
    align-items: center;
}

.nav-mobile-controls button {
    font-size: 12pt;

}

.nav-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 60px;
    background: linear-gradient(45deg, #70c72b, #1a9641);;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 10px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 2px 2px 8px #888;
    border: 1px solid #fff
}

.nav-cart:active {
    box-shadow: 1px 1px 7px #888;
    background: linear-gradient(45deg, #69bb27, #18863b);
}

.nav-cart-amount {
    display: flex;
    align-items: center;
    margin: 10px;
}

.nav-cart-total {
    margin-left: 5px;
}


/*---------- SideBar ----------*/
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0;
}

.sidebar-header h3 {
    margin-bottom: 10px;
    margin-left: 20px;
    padding: 0;
}

.sidebar-header div .icon-button {
    margin-top: 10px;
}

.sidebar-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    overflow-y: auto;
    margin-bottom: 50px;
}

.sidebar-body ul {
    list-style: none;
    width: 100%;
}

.sidebar-body ul li {
    padding: 20px;
    border-top: 1px solid #eee;
}

.sidebar-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
}

.sidebar-menu-item-icon {
    margin-right: 10px;
}

/*---------- App ----------*/
.app {
    padding-top: 60px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/*---------- Welcome Page ----------*/
.welcome-page {
    width: 100vw;
    height: 100vh;
    background-image: url("./static/images/welcome-4.jpeg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    font-size: 26px;
    padding-bottom: 50px;
}

.welcome-page-info {
    height: 100vh;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-shadow: 1px 1px 5px #000;
    margin-top: -60px;
    font-weight: bold;
    font-size: 32px;
}

.welcome-page-info p {
    margin-bottom: 60px;
    font-weight: 700;

}

/*---------- Signup Page ----------*/
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: calc(100vh - 60px);
    margin-top: 50px;
    margin-bottom: 50px;
}

/*---------- Welcome Page ----------*/
.success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    margin-top: 100px;
}

.success-icon {
    font-size: 4em;
    color: #1da649;
}

/*---------- Dashboard ----------*/
.page,
.dashboard-page,
.products-page {
    padding: 20px 20px 0;
    max-width: 1200px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card {
    box-shadow: 1px 1px 20px #3335;
    margin: 10px 10px 25px;
    border-radius: 10px;
    border: none;
    overflow: hidden;
    transition: 1s ease-in-out;
    max-width: 400px;
    min-width: 380px;
}

.card:hover .card-image-container img {
    transform: scale(1.05);
    transition: .2s ease-in-out;

}


.card-image-container {
    width: 100%;
    max-height: 350px;
    padding: 0;
    overflow: hidden;
    position: relative;
}

.card-image-container:hover .card-controls {
    margin-right: 0;
}

.card-image-container img {
    width: 100%;
    height: 100%;
    margin: -20px 0 0;
    padding: 0;
    transition: .3s ease-in-out;
}

.card-controls {
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 10px;
    margin-right: -100px;
    transition: .3s;
}

.card-controls .primary-button {
    box-shadow: -2px 5px 15px #000a;
}

.card-title-container {
    padding: 10px 20px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.card-title-container h6,
.card-title-container p {
    margin: 2px;
    font-size: 18px;
    font-weight: 500;
}

/*---------- Products page ----------*/
/*.products-page {*/
/*    padding: 0 20px;*/
/*}*/

/*---------- Product page ----------*/
.product-page {
    padding: 20px 20px;
}

.product-image-container {
    max-height: 400px;
    overflow: hidden;
    border-radius: 30px;
}

.product-image-container img {
    width: 100%;
    margin-top: -50px;
    margin-bottom: auto;
    height: 50%;
}

.product-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;

}

.product-description p {
    font-size: 18px;
    font-weight: 500;
    margin: 10px;
}

.product-ingredients {

}

.product-ingredients p {
    font-size: 12pt;
    text-align: justify;
    line-height: 1.2;
    font-weight: 300;
    margin: 10px;
}


/*---------- Cart Page ----------*/

.cart-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
}

.cart-card-image-container {
    width: 100px;
    height: 100%;
    overflow: hidden;
    margin-right: 8px;
}

.cart-page h3 {
    margin-bottom: 10px;
}

.cart-total-price {
    text-align: right;
    margin-top: 15px;
}


.cart-card-image-container img {
    width: 100%;
    margin-top: -10px;
}

.cart-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    box-shadow: 1px 1px 20px #3335;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    transition: .1s ease-in-out;
    cursor: pointer;
    height: 120px;
}

.cart-card:hover {
    transform: scale(1.01);
    transition: .1s ease-in-out;
}

.cart-card-info {
    display: flex;
}


.cart-card h5 {
    margin: 0;
}

.cart-card-details {
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.cart-card-details h5 {
    font-size: 15px;
}

.cart-card-details p {
    margin: 5px 0 0;
    font-size: 12px;
}

.cart-card-counter-controls {
    min-width: 30px;
    margin-top: 5px;
    display: flex;
}

.cart-card-counter-controls button {
    margin: 10px 10px 0 0;
    padding: 2px 20px !important;
}

.cart-card-controls {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 100%;
    overflow: hidden;
}

.cart-card-status {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.yellow-status,
.orange-status,
.red-status,
.green-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.yellow-status {
    background-color: rgba(200, 200, 0, 0.65);
}

.orange-status {
    background-color: orange;
}

.red-status {
    background-color: red;
}

.green-status {
    background-color: green;
}


/*---------- Footer ----------*/
.footer {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 20px;
    color: #ccc;
    border-top: 1px solid #eee;
}

.footer .developed-by a {
    color: #9f9e9e;
}

/*---------- Loading ----------*/
.loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: #FFFAFC;
    padding-top: 250px;
}

.loading-container img {
    width: 100%;
}

.product-ingredients h3 {
    display: none;
}

.logo-container h3 {
    font-size: 28px;
}

.contacts-info {
    font-size: 16px;
    margin-bottom: 30px;
}

.contacts-info p {
    margin: 10px 0;
}

.install-pwa-block {
    display: block;
    color: #000000;
    width: 200px;
    border-radius: 10px;
    min-height: 100px;
    margin: 0 auto;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.75);
    animation: dropDown 1s ease forwards;
}

@keyframes dropDown {
    from {
        top: -120px
    }
    to {
        top: 120px
    }

}

.close {
    display: flex;
    justify-content: right;
}
/*---------- Media Queries ----------*/

@media screen and (min-width: 1200px) {
    .install-pwa-block {
        display: none;
    }

    .contacts-info {
        width: 1120px;
        font-size: 18px;
        margin-left: 20px;
    }

    .contacts-info p {
        margin: 10px 0;
    }

    .contact-info-container {
        display: flex;
        flex-direction: row;
        width: 1120px;
    }

    .navigation {
        padding: 7px 15px;
    }

    .loading-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FFFAFC;
        padding-top: 250px;
        display: flex;
        justify-content: center;
    }

    .loading-container img {
        width: 400px;
        height: 300px;
    }


    .app {
        overflow: hidden;
        padding: 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(150deg, rgb(255, 255, 255) 20%, rgb(236, 236, 236) 50%, rgb(246, 246, 246) 70%);
        min-height: 100vh;
    }

    .signup, .login {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .login .signup-container {
        margin-top: 150px;
    }

    .signup-container {
        min-width: 600px;
        max-width: 1200px;
        margin-top: 100px;
        margin-bottom: 150px;
        min-height: 200px;
    }

    .signup-container .primary-button {
        margin-top: 20px;
    }


    .welcome-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 100vh;
        background-image: url("./static/images/welcome-41.jpeg");
        background-size: cover;
        padding-bottom: 0;
    }

    .welcome-page-info {
        max-width: 1000px;
        margin-top: 80px;
    }

    .navigation {
        max-width: 1200px;
    }

    .footer {
        padding-bottom: 0;
        padding-top: 10px;
        margin-top: 5px;
    }

    .footer-copyright {
        min-width: 1100px;
        max-width: 1200px;
    }

    .dashboard {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
    }

    .dashboard-page,
    .products-page,
    .product-page {
        background-color: rgb(193 210 190 / 76%);
        border-radius: 10px;
        padding-top: 0;
        padding-bottom: 150px;
        margin-top: 10px;
        width: 100% !important;
        max-width: 100%;
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: inset 2px 0 13px rgb(139, 148, 137);
    }

    .page-image {
        width: 100%;
        height: 200px;
        background-position: center;
        background-size: cover;
        /*background-image: url("https://images.unsplash.com/photo-1558030006-450675393462?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2231&q=80");*/
        background-attachment: fixed;
        overflow: hidden;
    }

    .page-image div{
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: brightness(.8) blur(2px);
    }

    .page-image div h1 {
        text-shadow: -2px 2px 10px #000;
        text-align: center;
        color: #fff;
    }

    .dashboard-page h3,
    .products-page h3 {
        /*width: 1130px;*/
        margin-top: 30px;
        margin-left: 10px;
        margin-bottom: 15px;
    }


    .menu-container {
        width: 1200px;
        display: flex;
    }

    .menu-container .category-sidebar {
        width: 300px;
        height: auto;
        border-right: 1px solid #999;
        padding: 0 20px;
        margin-top: 40px;
        margin-right: 20px;
    }

    .category-sidebar h4 {
        margin: -10px 0 10px;
        color: #2c2c2c;
    }

    .category-sidebar ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .category-sidebar ul li {
        padding-bottom: 10px;
    }

    .category-sidebar ul li a {
        color: #545454;

    }

    .menu-grid {
        display: flex;
        width: 900px;
        margin-top: 30px;
    }

    .product-info {
        width: 1150px;
    }

    .product-page,
    .cart-page,
    .page {
        width: 1150px;
        min-height: 60vh;
    }

    .cards-container {
        width: 1150px;
    }

    .cart-page {
        justify-content: flex-start;
    }

    .cart-items {
        position: relative;
    }

    .cart-items > .cart-total-price {
        width: 280px;
        background-color: #3b424f;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        height: 350px;
        box-shadow: 1px 1px 20px #3335;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 10px;
    }

    .cart-items > div {
        float: left;
        width: 800px;
    }

    .cart-items > .primary-button, .order-user-info .primary-button {
        width: 350px !important;
    }

    .product-image-container {
        float: left;
        width: 350px;
        margin-right: 30px;
        max-height: fit-content;
    }

    .product-image-container img {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
    }

    .card {
        min-width: 260px;
        max-width: 265px;
        background-color: #fff;
    }


    .cards-container {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .product-controls > .primary-button {
        width: 250px;
        border-radius: 10px;
        margin: 0;
    }

    .product-ingredients p {
        font-size: 18px;
    }

    .product-description p {
        font-size: 20px;
        padding: 20px 0;
    }

    .counter-input .primary-button {
        border-radius: 10px;

    }

    .counter-input .primary-button:first-child {
        margin-left: 0;
    }

    .counter-input .input-counter {
        border-radius: 10px;
    }

    .product-ingredients h3 {
        display: block;
    }

    /*.orders-list > div{*/
    /*    padding: 20px 0;*/
    /*}*/
    .orders-list hr {
        margin: 50px 0;
    }

    .nav-body {
        display: flex;
        align-items: center;
    }

    .nav-body ul {
        list-style: none;
        display: flex;
        align-items: center;
    }

    .nav-body ul li a {
        display: block;
        padding: 10px;
        font-size: 18px;
    }

    .menu-item {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .menu-item-icon {
        margin-right: 5px;
    }

    .nav-cart {
        background: none;
        color: #000;
        box-shadow: none;
        border: none;
    }

    .success-page {
        padding-top: 150px;
        min-height: 60vh;
    }

    .admin-page {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee;
        /*background: linear-gradient(120deg, #072C6A 10%, #040E21 50%, #47204A 90%);*/
    }

    .admin-page .login-form {
        width: 500px;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(25px);
        padding: 20px;
        border-radius: 20px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.26);
    }

    .admin-page .login-form-control {
        display: flex;
        justify-content: flex-end;
        padding: 10px 40px;
    }

}

/*---------- Admin Dashboard ----------*/
.admin-dashboard {
    display: flex;
    position: relative;
}

.admin-dashboard-menu {
    width: 300px;
    margin-right: 20px;
    padding: 20px;
    box-shadow: 5px 5px 10px #eee;
    height: 100vh;
}

.admin-dashboard-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;

}


.admin-dashboard-menu ul li {
    padding: 10px;
    border-bottom: 1px solid #cccccc;
}

.admin-dashboard-menu ul li:last-child {
    border-bottom: none;
}

.admin-dashboard-menu ul li span {
    cursor: pointer;
}

.add-category-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
}


.menu-table {
    display: flex;
    width: calc(100vw - 400px);
    overflow-x: auto;
    padding-bottom: 10px;
}

.menu-block {
    margin: 0 20px 10px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #b4b4b4;
    min-width: 250px;
}

.menu-block div {
    font-size: 16px;
}

.menu-block h5 {
    margin: 5px 0;
}

.df {
    display: flex;
}

.jcsb {
    justify-content: space-between;
}

.add-category-textarea {
    margin-top: 20px;
}

.admin-order-table {
    font-size: 16px;
    min-width: 1000px;
    overflow-x: auto;
    margin-bottom: 50px;
}

.admin-order-table table,
.admin-order-table tr,
.admin-order-table th,
.admin-order-table td {
    /*border: 1px solid #333;*/
    padding: 5px;
}

.admin-order-table thead tr {
    background-color: #dcdcdc;
}

.admin-order-table tbody tr:nth-child(even) {
    background-color: #f0f0f0;
}

.admin-order-table tbody tr:nth-child(odd) {
    background-color: #fff;
}


.admin-order-table td {
    text-align: right;
}

.order-status-navigation {
    margin-top: 20px;
}

.modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 99;
    background-color: #0005;
}

.modal {
    background-color: #fff;
    width: 750px;
    min-height: 300px;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    margin: 150px auto;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header h3 {
    font-size: 24px;
    margin: 0;
    padding: 0;
}

.modal-header .icon-button {
    padding: 4px 5px 0;
}

.admin-order-details {
    overflow: auto;
    padding-bottom: 10px;
}

.admin-order-details .admin-order-table th {
    font-size: 14px;
}

